<template>
  <div :id="`ID${scope._id}`">
    <template v-if="scope.bottomHead">
      <div
        v-if="scope._type || isPopover"
        :class="{
          sta: scope.bottomHead.align === 'left',
          stb: scope.bottomHead.align === 'right',
        }"
        class=""
        data-col="true"
      >
        <div v-if="!isPopover" class="btn_content">
          <date-picker
            v-if="scope._type == 'date'"
            v-model="scope.value"
            :type="scope.dateType"
            :format="scope.format"
            :valueType="scope.dateFormat"
            :use12h="false"
            :confirm="false"
            :range="scope.dateRange"
            @input="searchManager"
            :default-value="new Date()"

          />
          <template v-else-if="scope.multiselect">
            <v-select
              v-if="scope._type === true"
              :label="scope.label[0]"
              :options="scope.data"
              v-model="scope.value"
              :loading="scope.loading"
              :multiple="true"
              :no-drop="false"
              :clearInputAfterBlur="true"
              :autoTableHorizontal="true"
              inputClass="mx-input"
              :filter="
                (a, b) => fuseFilter(a, b, scope.label[0], scope.startsWithMode)
              "
              @search="
                (a, b) => {
                  if (true) {
                    searchManager(a, b);
                  }
                }
              "
              @input="searchManager"
              @inputing="(val) => (searchValue = val)"
              @close="clearMultiselectInput"
            >
              <template #spinner="{ loading }">
                <div v-show="loading" ref="loading" class="vs__spinner"></div>
                <span v-show="!loading" ref="btns" class="searchIcon">
                  <i
                    class="fas fa-search"
                    style="display: inline !important"
                  ></i>
                  <i
                    @click.stop="openSelectedItems"
                    @mousedown.stop=""
                    :style="0 ? 'color:#39f;' : ''"
                    class="fas fa-filter iconBtn"
                    style="display: inline !important"
                  ></i>
                </span>
              </template>
              <template #option="item">
                <span class="v-select_li_custom">
                  <span>{{ item[scope.label[0]] }}</span>
                  <i
                    v-tooltip="item[scope.label[1] || scope.label[0]]"
                    class="fas fa-info-circle"
                  ></i>
                </span>
              </template>
            </v-select>
            <v-select
              v-else-if="scope._type != 'date'"
              v-model="scope.value"
              :multiple="true"
              :taggable="true"
              :push-tags="true"
              :clearable="true"
              :searchable="true"
              :filterable="true"
              :close-on-select="true"
              :inputOnly="(val) => toLocaleNumber(val, !floatFormat)"
              inputClass="mx-input"
              @input="searchManager"
              @inputing="(val) => (searchValue = val)"
            >
              <template #spinner="{ loading }">
                <span v-show="!loading" ref="btns" class="searchIcon">
                  <i class="fas fa-search"></i>
                  <i
                    @click.stop="openSelectedItems"
                    @mousedown.stop=""
                    :style="0 ? 'color:#39f;' : ''"
                    class="fas fa-filter iconBtn"
                  ></i>
                </span>
              </template>
            </v-select>
          </template>
          <template v-else>
            <div
              data="SRCInput"
              :style="scope.viewRange ? 'display: flex;width:22rem;' : ''"
            >
              <template v-if="scope._type !== 'list'">
                <input
                  v-if="!scope.viewRange"
                  :id="`I${scope._id}`"
                  v-model="searchValue"
                  type="text"
                  class="mx-input"
                  style="margin: 0"
                  :style="scope.range ? 'min-width: 17rem;' : ''"
                />
                <template v-else>
                  <input
                    key="a1"
                    type="text"
                    v-model="scope.values[0]"
                    placeholder="De la"
                    class="mx-input"
                    style="
                      margin: 0;
                      padding: 0 1rem;
                      max-width: 100%;
                      margin-right: 1rem !important;
                    "
                  />
                  <input
                    key="a2"
                    type="text"
                    v-model="scope.values[1]"
                    placeholder="Până la"
                    class="mx-input"
                    style="
                      margin: 0;
                      padding: 0px 3.1rem 0 1rem;
                      max-width: 100%;
                    "
                  />
                </template>
              </template>
              <template v-else>
                <v-popover
                  popoverBaseClass="emptyPopover"
                  offset="8"
                  :open.sync="listOpened"
                  @hide="clearListInput"
                >
                  <input
                    :id="`I${scope._id}`"
                    type="text"
                    v-model="scope.searching"
                    class="mx-input"
                    style="margin: 0"
                    :style="scope.range ? 'min-width: 17rem;' : ''"
                  />

                  <div slot="popover" style="color: #c3c3c3; width: 35rem">
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #c3c3c3;
                        margin-bottom: 0.5rem;
                      "
                    >
                      <div class="sic_title">Opțiuni</div>
                      <i
                        v-close-popover
                        @click="unselectItemsFromList()"
                        class="fas fa-times clearAllFilter"
                      ></i>
                    </div>
                    <ol
                      v-if="filteredOptionsForListType.length"
                      class="sic_list selectableOptionsFromList highlightedBold"
                      style="margin: 0; text-align: left; padding-left: 2rem"
                    >
                      <li
                        v-for="e in filteredOptionsForListType"
                        :key="e.key || e.label"
                        @click="selectItemFromList(e._index)"
                        class="pointer"
                      >
                        <div :class="{ optionFromListActive: e.active }">
                          <span style="flex-grow: 1" v-html="e.label"></span>
                          <span
                            class="selectableOptionsIndicator"
                            style="flex-grow: 0"
                          ></span>
                        </div>
                      </li>
                    </ol>
                    <p
                      v-else
                      class="sic_notSelected"
                      style="padding: 0; margin: 0"
                    >
                      <i class="fas fa-times"></i> Nimic găsit
                    </p>
                  </div>
                </v-popover>
              </template>
            </div>
            <span
              ref="btns"
              class="searchIcon"
              :style="scope.viewRange ? 'right: 0.6rem;' : ''"
            >
              <i
                v-if="scope.range"
                @click="watchNumberRange()"
                class="fas fa-exchange-alt iconBtn"
                style="margin-right: 0.6rem; display: inline-block !important"
              ></i>
              <label v-if="!scope.viewRange" :for="`I${scope._id}`">
                <span
                  v-if="filteredOptionsForListType.find((e) => e.active)"
                  class="selectableOptionsIndicator"
                  style="margin-right: 0.5rem"
                ></span>
                <i
                  :style="lastSent !== '' ? '' : 'display: inline !important;'"
                  class="fas fa-search"
                ></i>
              </label>
              <span
                v-if="lastSent !== ''"
                @click="clearInput"
                class="mx-icon close"
                data="clearSearch"
              ></span>
            </span>
          </template>
          <transition name="user_inner_dropdown">
            <div
              v-if="showSelectedItems"
              v-click-outside="openSelectedItems"
              class="selectedItemsContainer"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border-bottom: 1px solid #c3c3c3;
                  margin-bottom: 0.5rem;
                "
              >
                <div class="sic_title">Filtru</div>
                <i
                  @click="deleteItemFromValue(true)"
                  class="fas fa-times clearAllFilter"
                ></i>
              </div>
              <div class="sic_list">
                <ol v-if="0">
                  <li v-for="(item, i) in scope.value" :key="i">
                    <div>
                      <span>{{ getItemValue(item) }}</span>
                      <div class="sic_list_actions">
                        <div class="sic_list_remove">
                          <i
                            @click="deleteItemFromValue(i)"
                            class="fas fa-times"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </li>
                </ol>
                <p v-else class="sic_notSelected">Nimic selectat</p>
              </div>
            </div>
          </transition>
        </div>
        <v-popover
          v-else-if="scope._type !== 'clear'"
          trigger="hover"
          :autoHide="false"
          popoverBaseClass="emptyPopover"
        >
          <div>
            <i
              style="padding: 0.5rem"
              :style="isValueActive ? 'color:#39f;' : ''"
              class="fas fa-filter iconBtn"
            ></i>
          </div>

          <div slot="popover" class="statusBarSelect">
            <template v-if="isIconsMap">
              <div
                v-for="(e, i) in iconsMap"
                :key="JSON.stringify(e)"
                :class="{ statusActive: scope.value[i] }"
              >
                <i
                  v-tooltip="e.tooltip || 'Click'"
                  @click="statusesManager(i)"
                  :class="e.icon"
                  :style="`font-size:2.5rem;color:${e.color || '#39f'};`"
                ></i>
              </div>
            </template>
            <template v-else-if="scope._type === 'status'">
              <div :class="scope.value[8] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Generat'"
                  class="fas fa-sparkles"
                  @click="statusesManager(8)"
                  style="font-size: 2.5rem; color: #f2da07"
                ></i>
              </div>
              <div
                v-if="approveMode"
                :class="scope.value[0] ? 'statusActive' : ''"
              >
                <i
                  v-tooltip="'Aprobat'"
                  class="fas fa-clipboard-check"
                  @click="statusesManager(0)"
                  style="font-size: 2.5rem; color: #569a36"
                ></i>
              </div>
              <div
                v-if="approveMode"
                :class="scope.value[1] ? 'statusActive' : ''"
              >
                <i
                  v-tooltip="'Așteptare aprobare'"
                  class="far fa-clock"
                  @click="statusesManager(1)"
                  style="font-size: 2.5rem; color: #f2da07"
                ></i>
              </div>
              <div
                v-if="approveMode"
                :class="scope.value[2] ? 'statusActive' : ''"
              >
                <i
                  v-tooltip="'Respins'"
                  class="fas fa-times-circle"
                  @click="statusesManager(2)"
                  style="font-size: 2.5rem; color: #ec4a4a"
                ></i>
              </div>
              <div
                v-if="avizoMode"
                :class="scope.value[3] ? 'statusActive' : ''"
              >
                <i
                  v-tooltip="'În proces <br/>de avizare'"
                  class="fas fa-low-vision"
                  @click="statusesManager(3)"
                  style="font-size: 2.5rem; color: #39f"
                ></i>
              </div>
              <div
                v-if="avizoMode"
                :class="scope.value[4] ? 'statusActive' : ''"
              >
                <i
                  v-tooltip="'Așteptare editare în <br/>urma avizării negative'"
                  class="fas fa-times-circle"
                  @click="statusesManager(4)"
                  style="font-size: 2.5rem; color: #39f"
                ></i>
              </div>
              <div
                v-if="avizoMode"
                :class="scope.value[5] ? 'statusActive' : ''"
              >
                <i
                  v-tooltip="'Așteptare aprobare <br/> în urma avizării'"
                  class="far fa-calendar-check"
                  @click="statusesManager(5)"
                  style="font-size: 2.5rem; color: #337ab7"
                ></i>
              </div>
              <div
                v-if="approveMode"
                :class="scope.value[6] ? 'statusActive' : ''"
              >
                <i
                  v-tooltip="'Anulat'"
                  class="fas fa-ban"
                  @click="statusesManager(6)"
                  style="font-size: 2.5rem; color: #ec4a4a"
                ></i>
              </div>

              <div :class="scope.value[7] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Dezactivat'"
                  class="fas fa-exclamation-circle"
                  @click="statusesManager(7)"
                  style="font-size: 2.5rem; color: red"
                ></i>
              </div>
            </template>
            <template v-else-if="scope._type === 'type'">
              <div :class="scope.value[0] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Deblocat'"
                  class="fas fa-unlock"
                  @click="statusesManager(0)"
                  style="font-size: 2.5rem; color: #3c763d"
                ></i>
              </div>
              <div :class="scope.value[1] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Blocat'"
                  class="fas fa-lock"
                  @click="statusesManager(1)"
                  style="font-size: 2.5rem; color: #a94442"
                ></i>
              </div>
              <div
                v-tooltip="'Complex'"
                class="imG"
                :class="scope.value[2] ? 'statusActive' : ''"
                @click="statusesManager(2)"
              >
                <img src="@/assets/media/for_table/complex.svg" alt="" />
              </div>
              <div :class="scope.value[3] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Similar'"
                  class="fas fa-check-double"
                  @click="statusesManager(3)"
                  style="font-size: 2.5rem; color: #286090"
                ></i>
              </div>
            </template>
            <template v-else-if="scope._type === 'userStatus'">
              <div :class="scope.value[0] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Status activat'"
                  class="fas fa-check-circle"
                  @click="statusesManager(0)"
                  style="font-size: 2.5rem; color: green"
                ></i>
              </div>
              <div :class="scope.value[1] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Status dezactivat'"
                  class="fas fa-exclamation-circle"
                  @click="statusesManager(1)"
                  style="font-size: 2.5rem; color: red"
                ></i>
              </div>
              <div :class="scope.value[2] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Status neconfirmat'"
                  class="far fa-clock"
                  @click="statusesManager(2)"
                  style="font-size: 2.5rem; color: #f2da07"
                ></i>
              </div>
            </template>
            <template v-else-if="scope._type === 'needReferate'">
              <div :class="scope.value[0] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Referat existent'"
                  class="fas fa-file"
                  @click="statusesManager(0)"
                  style="font-size: 2.5rem; color: #39f"
                ></i>
              </div>
              <div :class="scope.value[1] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Referat inexistent'"
                  class="far fa-file"
                  @click="statusesManager(1)"
                  style="font-size: 2.5rem; color: #39f"
                ></i>
              </div>
            </template>
            <template v-else-if="scope._type === 'statusReference'">
              <div :class="scope.value[0] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Aprobat'"
                  class="fas fa-clipboard-check"
                  @click="statusesManager(0)"
                  style="font-size: 2.5rem; color: #569a36"
                ></i>
              </div>
              <div :class="scope.value[1] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Respins'"
                  class="fas fa-times-circle"
                  @click="statusesManager(1)"
                  style="font-size: 2.5rem; color: #ec4a4a"
                ></i>
              </div>
              <div :class="scope.value[2] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Așteptare semnare'"
                  class="fas fa-clock"
                  @click="statusesManager(2)"
                  style="font-size: 2.5rem; color: #f2da07"
                ></i>
              </div>
              <div :class="scope.value[3] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Draft'"
                  class="far fa-clock"
                  @click="statusesManager(3)"
                  style="font-size: 2.5rem; color: #f2da07"
                ></i>
              </div>
              <div :class="scope.value[5] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Trimis spre completare'"
                  class="fas fa-pencil"
                  @click="statusesManager(5)"
                  style="font-size: 2.5rem; color: #F2994A"
                ></i>
              </div>
              <div :class="scope.value[6] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Completat'"
                  class="fas fa-pencil"
                  @click="statusesManager(6)"
                  style="font-size: 2.5rem; color: #569a36"
                ></i>
              </div>
              <div :class="scope.value[4] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Anulat'"
                  class="fas fa-exclamation-circle"
                  @click="statusesManager(4)"
                  style="font-size: 2.5rem; color: #ce302d"
                ></i>
              </div>

              <div :class="scope.value[7] ? 'statusActive' : ''">
                <i
                  v-tooltip="'În curs de aprobare'"
                  class="fas fa-pen-nib"
                  @click="statusesManager(7)"
                  style="font-size: 2.5rem; color: #F2994A"
                ></i>
              </div>
            </template>
            <template v-else-if="scope._type === 'paap'">
              <div :class="scope.value[0] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Aprobat'"
                  class="fas fa-clipboard-check"
                  @click="statusesManager(0)"
                  style="font-size: 2.5rem; color: #569a36"
                ></i>
              </div>
              <div :class="scope.value[1] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Respins'"
                  class="fas fa-times-circle"
                  @click="statusesManager(1)"
                  style="font-size: 2.5rem; color: #ec4a4a"
                ></i>
              </div>
              <div :class="scope.value[2] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Așteptare aprobare'"
                  class="fas fa-clock"
                  @click="statusesManager(2)"
                  style="font-size: 2.5rem; color: #f2da07"
                ></i>
              </div>

              <div :class="scope.value[3] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Draft'"
                  class="far fa-clock"
                  @click="statusesManager(3)"
                  style="font-size: 2.5rem; color: #f2da07"
                ></i>
              </div>
            </template>
            <template v-else-if="scope._type == 'acquisitionProductStatus'">
              <div :class="scope.value[0] ? 'statusActive' : ''">
                <i
                  v-tooltip="{ content: 'Draft' }"
                  class="far fa-clock"
                  @click="statusesManager(0)"
                  style="font-size: 2.5rem; color: #f2da07"
                ></i>
              </div>

              <div :class="scope.value[1] ? 'statusActive' : ''">
                <i
                  v-tooltip="{ content: 'Completat' }"
                  class="fas fa-clipboard-check"
                  @click="statusesManager(1)"
                  style="font-size: 2.5rem; color: #569a36"
                ></i>
              </div>
            </template>
            <template v-else-if="scope._type == 'acquisitionStatus'">
              <div :class="scope.value[0] ? 'statusActive' : ''">
                <i
                  v-tooltip="{ content: 'Draft' }"
                  class="far fa-clock"
                  @click="statusesManager(0)"
                  style="font-size: 2.5rem; color: #f2da07"
                ></i>
              </div>

              <div :class="scope.value[1] ? 'statusActive' : ''">
                <i
                  v-tooltip="{ content: 'Generat' }"
                  class="fas fa-pencil"
                  @click="statusesManager(1)"
                  style="font-size: 2.5rem; color: #569a36"
                ></i>
              </div>

              <div :class="scope.value[2] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Așteptare aprobare'"
                  class="fas fa-clock"
                  @click="statusesManager(2)"
                  style="font-size: 2.5rem; color: #f2da07"
                ></i>
              </div>

              <div :class="scope.value[3] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Aprobat'"
                  class="fas fa-clipboard-check"
                  @click="statusesManager(3)"
                  style="font-size: 2.5rem; color: #569a36"
                ></i>
              </div>
              <div :class="scope.value[4] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Respins'"
                  class="fas fa-times-circle"
                  @click="statusesManager(4)"
                  style="font-size: 2.5rem; color: #ec4a4a"
                ></i>
              </div>
              <div :class="scope.value[5] ? 'statusActive' : ''">
                <i
                  v-tooltip="'Finalizat'"
                  class="fas fa-badge-check"
                  @click="statusesManager(5)"
                  style="font-size: 2.5rem; color: green"
                ></i>
              </div>
            </template>
          </div>
        </v-popover>
        <div v-else style="text-align: center">
          <i
            @click="$emit('clearAllFilter')"
            :style="
              Array.isArray(scope.value) && scope.value.find((e) => e)
                ? 'color:#39f;'
                : ''
            "
            class="fas fa-times iconBtn"
          ></i>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import DatePicker from "@/mixins/DatePicker.js";

const moment = require("moment");
const labelsMap = {
  CPV_LIST: ["cpvCode", "name"],
  CONTRACT_TYPE: ["contractType", "contractType"],
  FINANCING: ["sourceName", "sourceName"],
  MU_LIST: ["shortName", "description"],
};
const maps = {
  status: [
    "approved",
    "pending",
    "rejected",
    "avizo",
    "editing",
    "approvalReady",
    "cancelled",
    "disabled",
    "generated",
  ],
  statusReference: [
    "approved",
    "rejected",
    "signature_waiting",
    "draft",
    "cancelled",
    "completion",
    "completed",
    "in_progress_signature",
  ],
  paap: ["approved", "rejected", "asteptare_aprobare", "draft"],
  acquisitionStatus: [
    "draft",
    "generated",
    "waiting_approve",
    "approved",
    "rejected",
    "closed",
  ],
  acquisitionProductStatus: ["draft", "completed"],
  type: ["open", "locked", "complex", "similar"],
  userStatus: ["active", "disabled", "unconfirmed"],
  needReferate: [["0"], "0"],
};
const numbersTypes = ["number", "numberFloat"];
const startsWithMap = [];

const searchCache = [];
const getCurrentTime = () => new Date().getTime();

let searchTimeoutCallback = null;
let listOpeningTimer = null;
let lastSearchingTime = 0;
let stopSearchValueWatcher = false;
let changedTimeoutCallback = null;
let lastChangedTime = 0;
let stopRangeWatcher = false;
const districtsMap = [
  ["a", "ă", "â"],
  ["t", "ț", "ţ"],
  ["s", "ș"],
  ["i", "î"],
];

export default {
  mixins: [DatePicker],
  props: {
    selectedItemsIndex: {
      type: Number,
      default: -1,
      required: true,
    },
    index: {
      type: Number,
      default: -1,
      required: true,
    },
    item: {
      type: Object,
      default: {},
      required: true,
    },
    reset: {
      type: Number,
      default: 0,
    },
    avizoMode: {
      type: Boolean,
      default: true,
    },
    approveMode: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    iconsMap() {
      return this.scope.bottomHead?.iconsMap || [];
    },
    isIconsMap() {
      const x = this.scope.bottomHead?.iconsMap;
      return Array.isArray(x) && x.length;
    },
    isPopover() {
      return (
        this.isIconsMap ||
        [
          "type",
          "status",
          "clear",
          "userStatus",
          "needReferate",
          "statusReference",
          "paap",
          "acquisitionStatus",
          "acquisitionProductStatus",
        ].includes(this.scope._type)
      );
    },
    isValueActive() {
      return Array.isArray(this.scope.value) && this.scope.value.find((e) => e);
    },
    showSelectedItems() {
      return this.selectedItemsIndex === this.index;
    },
    filteredOptionsForListType() {
      const w = this.scope.searching?.trim();
      if (!w) return this.scope.options || [];
      const x = this.scope.options;

      let inp = this.RegQuote(w);
      districtsMap.forEach(
        (e) =>
          (inp = inp.replace(
            new RegExp(`[${e.join("")}]`, "gi"),
            `[${e.join("")}]`
          ))
      );

      const y = new RegExp(inp, "gi");

      return x
        ? x
            .filter((e) => {
              y.lastIndex = 0;
              return y.test(e.label);
            })
            .map((e) => ({ ...e, label: e.label.replace(y, (w) => w.bold()) }))
        : [];
    },
    listOpened: {
      get() {
        return this.scope.opened;
      },
      set(x) {
        clearTimeout(listOpeningTimer);

        listOpeningTimer = setTimeout(() => {
          this.scope.opened = x;
        }, 120);
      },
    },
  },
  data() {
    return {
      scope: {},
      searchValue: "",
      lastSent: "",
      floatFormat: false,
      stopOnceSearchManager: false,
    };
  },
  beforeDestroy() {
    stopRangeWatcher = false;
    searchCache.length = 0;
    clearTimeout(searchTimeoutCallback);
    lastSearchingTime = 0;

    clearTimeout(changedTimeoutCallback);
    lastChangedTime = 0;
  },
  methods: {
    clearInput() {
      this.searchManager("");
      this.lastSent = this.searchValue = "";
      if (this.scope.range) {
        this.scope.values = [NaN, NaN];
      }
      this.unselectItemsFromList();
    },
    clearMultiselectInput() {
      this.searchValue = "";
    },
    getMultiselectInput() {
      return document.querySelector(`#ID${this.scope._id} .vs__search`) || null;
    },
    getStatuses() {
      const prepare = [];
      const map = this.item?.bottomHead?.queryMap || maps[this.scope._type];
      if (!map) {
        return [];
      }
      this.scope.value.forEach((e, i) =>
        e ? prepare.push(map[i] || false) : false
      );
      return prepare.filter((e) => e);
    },
    statusesManager(index) {
      if (!Number.isInteger(index)) {
        return;
      }
      this.scope.value.splice(index, 1, !this.scope.value[index]);
      if (!~this.scope.value.findIndex((e) => !e)) {
        this.searchManager();
        return;
      }
      const val = this.getStatuses();

      this.searchManager(val);
    },
    openSelectedItems() {
      this.$emit("openSelectedItems", this.showSelectedItems ? -1 : this.index);
    },
    deleteItemFromValue(index) {
      if (Number.isInteger(index)) this.scope.value.splice(index, 1);
      else {
        this.scope.value.splice(0);
        this.openSelectedItems();
      }
      this.searchManager(this.scope.value);
    },
    getItemValue(item) {
      if (item === undefined) return;
      return (
        item[
          Array.isArray(this.scope.label) ? this.scope.label[0] : undefined
        ] || item
      );
    },
    getLoadedData(data, loadData) {
      if (Array.isArray(data) && data.length) {
        const prepareNewData = [];
        data.forEach((e) => {
          if (!this.scope.data.find((old) => old.id === e.id)) {
            prepareNewData.push(e);
          }
        });
        this.scope.data = prepareNewData.concat(this.scope.data);
      }
      if (typeof loadData == "function") {
        loadData(false);
        this.scope.loading = false;
      }
    },
    prepareInputValue(val) {
      if (this.scope._type == "date" && this.scope.range) {
        /*         val = val.map((e) =>
          e
            ? moment(
                moment(e, "x").format("YYYY-MM-DDT00:00:00.000") + "Z"
              ).format("x")
            : e
        );

        val[1] = String(parseInt(val[1]) + 86400000); */
        const x = val /* .filter((e) => parseInt(e)) */
          .join("|");

        return x && x !== "|" ? x : "";
      }
      if (!this.scope.multiselect) {
        return val;
      }
      if (!Array.isArray(val)) return;
      if (this.scope._type === true) {
        return val.map((e) => e[labelsMap[this.scope.metaType][0]]);
      }
      if (this.scope._type === "numberFloat") {
        return val.map((e) => this.getLocaleNumber(e));
      }
      if (this.scope._type === "number" || this.scope._type === "date") {
        return val.map((e) => parseInt(e));
      }
      return val;
    },
    searchManager(inputValue, loadData) {
      const exactSearch = this.scope.bottomHead?.exactSearch === true;
      if (
        (!inputValue && this.scope.multiselect) ||
        (this.stopOnceSearchManager && this.scope.values)
      ) {
        this.stopOnceSearchManager = false;
        return;
      }
      if (loadData && inputValue) {
        if (searchCache.includes(String(inputValue))) {
          return;
        }
        clearTimeout(searchTimeoutCallback);
        searchTimeoutCallback = setTimeout(
          () => {
            lastSearchingTime = getCurrentTime();
            const x = String(inputValue).trim();
            if (x) searchCache.push(x);
            if (typeof loadData == "function") {
              loadData(true);
              this.scope.loading = true;
            }
            this.$emit("inputted", this.index, {
              callback: (data) => this.getLoadedData(data, loadData),
              inputData: Object.assign(
                inputValue
                  ? {
                      inputValue:
                        exactSearch && !Array.isArray(inputValue)
                          ? [inputValue, true]
                          : inputValue,
                    }
                  : {},
                this.scope
              ),
            });
            clearTimeout(searchTimeoutCallback);
          },
          getCurrentTime() - lastSearchingTime > 400 ? 1 : 400
        );
      } else {
        clearTimeout(changedTimeoutCallback);
        changedTimeoutCallback = setTimeout(
          () => {
            lastChangedTime = getCurrentTime();
            this.lastSent = inputValue;
            const prepared = this.prepareInputValue(inputValue);
            this.$emit("changed", this.index, {
              callback: () => {},
              inputData: Object.assign(this.scope, {
                inputValue:
                  prepared && exactSearch && !Array.isArray(prepared)
                    ? [[prepared, true]]
                    : prepared,
              }),
            });
            clearTimeout(changedTimeoutCallback);
          },
          getCurrentTime() - lastChangedTime > 400 ? 1 : 400
        );
      }
    },
    resizeInput() {
      if (!(this.scope._type && this.scope._type !== "date")) {
        return;
      }
      try {
        let totalWidth = this.scope.loading ? 15 : 0;
        const btns = this.$refs["btns"];

        if (btns) {
          totalWidth += btns.offsetWidth + 15 || 0;
        }

        if (totalWidth) {
          if (this.scope.searchInput) {
            this.scope.searchInput.style.paddingRight = `${totalWidth}px`;
          } else {
            const findInput = document.querySelector(
              `#ID${this.scope._id} .vs__search`
            );
            if (findInput) {
              findInput.style.paddingRight = `${totalWidth}px`;
              this.scope.searchInput = findInput;
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    watchNumberRange(x) {
      this.scope.viewRange = x || !this.scope.viewRange;
      this.searchValue = "";
      this.scope.values = ["", ""];
      this.lastSent = "";

      this.searchManager([0, 0]);
    },
    clearListInput() {
      if (!this.listOpened) this.scope.searching = "";
    },
    sendSelectedItemsFromList() {
      const x = this.scope.options;
      if (!Array.isArray(x)) return;

      if (!~x.findIndex((e) => !e.active)) {
        this.searchManager();
        return;
      }
      this.searchManager(
        x.filter((e) => e.active).map((e) => e.key || e.value || e.label)
      );
    },
    selectItemFromList(index) {
      if (!Number.isInteger(index) || !Array.isArray(this.scope.options))
        return;
      const x = this.scope.options[index];
      this.scope.options.splice(index, 1, { ...x, active: !x.active });

      this.sendSelectedItemsFromList();
    },
    unselectItemsFromList() {
      if (
        !Array.isArray(this.scope.options) ||
        !this.scope.options.find((e) => e.active)
      )
        return;
      this.scope.options.forEach((e) => (e.active = false));

      this.sendSelectedItemsFromList();
    },
  },
  watch: {
    searchValue(val) {
      if (stopSearchValueWatcher) {
        stopSearchValueWatcher = false;
        return;
      }
      this.searchValue = numbersTypes.includes(this.scope._type)
        ? this.toLocaleNumber(val, !this.floatFormat)
        : val;
      if (val !== this.searchValue) {
        if (!this.searchValue && (!this.lastSent || this.lastSent === val)) {
          stopSearchValueWatcher = true;
        }
        return;
      }
      this.resizeInput();
      if (this.scope._type !== "date" && !this.scope.multiselect) {
        this.scope.value =
          this.scope._type === "numberFloat" ? this.getLocaleNumber(val) : val;
        this.searchManager(this.scope.value);
      }
    },
    reset() {
      this.clearInput();
    },
  },
  created() {
    const x = this.item;
    if (x.metaType && labelsMap[x.metaType]) {
      x.label = labelsMap[x.metaType];
    }
    if (this.item.defaultValue) this.searchValue = this.item.defaultValue;

    this.scope = Object.assign(
      {
        _id: this.makeid(8),
        searchInput: null,
        dateType: x?.bottomHead?.dateType ?? "date",
        dateFormat: x?.bottomHead?.dateFormat ?? "",
        dateRange: x?.bottomHead?.range ?? false,
        format: x?.bottomHead?.format ?? 'DD-MM-YYYY',
      },
      startsWithMap.includes(x.queryKey) ? { startsWithMode: true } : {},
      x?.bottomHead?.range
        ? { range: true, values: ["", ""], viewRange: false }
        : { range: false },
      x?.bottomHead?.multiselect
        ? { multiselect: true, value: x.value || [] }
        : { multiselect: false, value: x.value || "" },
      x.bottomHead?.quikFiltre ? { _type: x.bottomHead.quikFiltre } : {},
      Array.isArray(x.bottomHead?.options)
        ? {
            options: x.bottomHead.options.map((e, i) => ({
              label: e.label || e.value || e,
              value: e.value || e,
              e,
              _index: i,
            })),
            searching: "",
            active: false,
            opened: false,
          }
        : {},
      x
    );

    if (this.scope._type === "numberFloat") {
      this.floatFormat = true;
    }
    if (this.scope.range) {
      this.$watch("scope.values", (val) => {
        if (stopRangeWatcher) {
          stopRangeWatcher = false;
          return;
        }
        this.scope.values[0] = this.toLocaleNumber(val[0], !this.floatFormat);
        this.scope.values[1] = this.toLocaleNumber(val[1], !this.floatFormat);
        const x = parseFloat(val[0]) || 0;
        const y = parseFloat(val[1]) || 0;

        if (
          this.scope.values[0] !== val[0] ||
          this.scope.values[1] !== val[1]
        ) {
          return;
        }
        this.searchManager([Math.min(x, y), Math.max(x, y)]);
      });
    }
  },
  updated() {
    this.resizeInput();
  },
  mounted() {
    this.resizeInput();
  },
};
</script>

<style></style>
